import React from 'react'
import PropTypes from 'prop-types'
import Script from 'react-load-script'
import './Adsense.scss'

class Adsense extends React.Component {
  shouldRender = () =>
    process.env.NODE_ENV === 'production' &&
    typeof window !== 'undefined'

  handleAdsenseLoad = () => {
    if (this.shouldRender()) {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }
  
  render () {
    let adsenseView
    if (this.props.type === 'article-top') {
      adsenseView = <ArticleTop />
    } else if (this.props.type === 'article-middle') {
      adsenseView = <ArticleMiddle />
    } else if (this.props.type === 'article-bottom') {
      adsenseView = <ArticleBottom />
    } else if (this.props.type === 'sidebar-top') {
      adsenseView = <SidebarTop />
    } else if (this.props.type === 'sidebar-middle') {
      adsenseView = <SidebarMiddle />
    } else if (this.props.type === 'sidebar-bottom') {
      adsenseView = <SidebarBottom />
    } else if (this.props.type === 'article-top-responsive') {
      adsenseView = <ArticleTopResponsive />
    } else if (this.props.type === 'article-bottom-responsive') {
      adsenseView = <ArticleBottomResponsive />
    } else {
      adsenseView = null
    }

    return (
      <React.Fragment>
        <Script
          url="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6533397327101411"
          attributes = {{async: true, crossorigin: "anonymous"}}
          onLoad={this.handleAdsenseLoad}
        />
        <div className="adsense-code-block" id="ad-slot">{adsenseView}</div>
      </React.Fragment>
    )
  }
}

const ArticleTop = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'inline-block', width: '300px', height: '250px' }}
    data-ad-client="ca-pub-6533397327101411"
    data-ad-slot="1443568091"
  />
)

const ArticleMiddle = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'inline-block', width: '300px', height: '250px' }}
    data-ad-client="ca-pub-6533397327101411"
    data-ad-slot="1579299463"
  />
)

const ArticleBottom = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'inline-block', width: '300px', height: '250px' }}
    data-ad-client="ca-pub-6533397327101411"
    data-ad-slot="7095759140"
  />
)

const SidebarTop = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'inline-block', width: '300px', height: '250px' }}
    data-ad-client="ca-pub-6533397327101411"
    data-ad-slot="7461279901"
  />
)

const SidebarMiddle = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'inline-block', width: '300px', height: '600px' }}
    data-ad-client="ca-pub-6533397327101411"
    data-ad-slot="4446810420"
  />
)

const SidebarBottom = props => (
  <ins className="adsbygoogle"
      style={{ display: 'inline-block', width: '300px', height: '250px' }}
      data-ad-client="ca-pub-6533397327101411"
      data-ad-slot="7277970707"></ins>
)

const ArticleTopResponsive = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'block' }}
    data-ad-client="ca-pub-6533397327101411"
    data-ad-slot="2190824005"
    data-ad-format="auto"
    data-full-width-responsive="true"
  />
)

const ArticleBottomResponsive = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'block' }}
    data-ad-client="ca-pub-6533397327101411"
    data-ad-slot="4166405878"
    data-ad-format="auto"
    data-full-width-responsive="true"
  />
)

const ArticleDummy = props => (
  <ins
    className="adsbygoogle"
    style={{ display: 'block' }}
    data-ad-client="ca-pub-xxxxxx"
    data-ad-slot="xxxx"
    data-ad-format="auto"
    data-full-width-responsive="true"
  />
)

Adsense.propTypes = {
  type: PropTypes.string,
}

Adsense.defaultProps = {
  type: '',
}

export default Adsense
